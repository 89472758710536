import React, { useCallback, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { SelectCreditCardYearsArray, SelectMonthsArray } from "../../Constants";
import CustomInputComponent from "./rtoDynamicForm/fields/CustomInputComponent";
import CustomSelectComponent from "./rtoDynamicForm/fields/CustomSelectComponent";
import CustomInputZipComponent from "./rtoDynamicForm/fields/CustomInputZipComponent";
import CustomInputCCNTokenizerComponent from "./rtoDynamicForm/fields/CustomInputCCNTokenizerComponent";

const CreditCardAddForm = ({
	tokenizerUrl,
	setIsSubmitDisabled,
	addCardInfo,
	setAddCardInfo,
}) => {
	// const handleIframeMessage = useCallback(
	// 	(event) => {
	// 		try {
	// 			const tokenData = JSON.parse(event.data);

	// 			if (tokenData.message) {
	// 				// Set the token value to the form
	// 				setToken(tokenData.message);
	// 				setAddCardInfo((prevState) => ({
	// 					...prevState,
	// 					token: tokenData.message,
	// 				}));
	// 				setCardError(null);
	// 			} else if (tokenData.validationError) {
	// 				// Set the token value to empty
	// 				setToken("");
	// 				// Set the card error
	// 				setCardError("Card Number is invalid.");
	// 			}
	// 		} catch (error) {
	// 			console.error("Failed to parse event data:", error);
	// 		}
	// 	},
	// 	[setToken]
	// );

	// useEffect(() => {
	// 	// Add event listener for the iframe message
	// 	window.addEventListener("message", handleIframeMessage);
	// 	return () => {
	// 		// Clean up event listener
	// 		window.removeEventListener("message", handleIframeMessage);
	// 	};
	// }, [handleIframeMessage]);

	const handleInputChange = (fieldName, value, isValid) => {
		setAddCardInfo((prevState) => ({
			...prevState,
			[fieldName]: value,
		}));
		console.log("Card Info: ", addCardInfo);
	};

	return (
		<div>
			<Grid container spacing={2} marginTop={2}>
				<Grid item xs={6}>
					<CustomInputComponent
						id="first_name"
						name="first_name"
						label="First Name"
						value={addCardInfo?.first_name}
						type={{ required: true, upperCase: true }}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(fieldName, value, pIsValid) => {
							handleInputChange(fieldName, value, pIsValid);
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomInputComponent
						id="last_name"
						name="last_name"
						label="Last Name"
						value={addCardInfo?.last_name}
						type={{ required: true, upperCase: true }}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(fieldName, value, pIsValid) => {
							handleInputChange(fieldName, value, pIsValid);
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} marginBottom={"20px"}>
					<CustomInputCCNTokenizerComponent
						key="card_number"
						name="card_number"
						label="Creditcard Number"
						value=""
						required={true}
						targetToken="token"
						tokenizerUrl={tokenizerUrl}
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomSelectComponent
						id="month"
						name="month"
						label="Month"
						type={{
							options: SelectMonthsArray,
						}}
						value={addCardInfo?.month}
						onChange={(fieldName, value, pIsValid) =>
							handleInputChange(fieldName, value, pIsValid)
						}
						className={""}
						required={true}
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomSelectComponent
						id="year"
						name="year"
						label="Year"
						type={{
							options: SelectCreditCardYearsArray,
						}}
						value={addCardInfo?.year}
						onChange={(fieldName, value, pIsValid) =>
							handleInputChange(fieldName, value, pIsValid)
						}
						className={""}
						required={true}
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomInputComponent
						id="cvv"
						name="cvv"
						label="CVV"
						value={addCardInfo?.cvv}
						type={{ required: true, minLength: 3, maxLength: 4 }}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(fieldName, value, pIsValid) => {
							handleInputChange(fieldName, value, pIsValid);
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomInputComponent
						id="billing_address"
						name="billing_address"
						label="Billing Address"
						value={addCardInfo?.billing_address}
						type={{ required: true }}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(fieldName, value, pIsValid) => {
							handleInputChange(fieldName, value, pIsValid);
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomInputZipComponent
						id="zip"
						name="zip"
						label="zip"
						value={addCardInfo?.zip}
						type={{ required: true }}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(fieldName, value, pIsValid) => {
							handleInputChange(fieldName, value, pIsValid);
						}}
						required={true}
						target_city="city"
						target_state="state"
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomInputComponent
						id="city"
						name="city"
						label="City"
						value={addCardInfo?.city}
						type={{ required: true, readOnly: true }}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(fieldName, value, pIsValid) => {
							handleInputChange(fieldName, value, pIsValid);
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomInputComponent
						id="state"
						name="state"
						label="State"
						value={addCardInfo?.state}
						type={{ required: true, readOnly: true }}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(fieldName, value, pIsValid) => {
							handleInputChange(fieldName, value, pIsValid);
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomInputComponent
						id="token"
						name="token"
						label="Token"
						value={addCardInfo?.token}
						type={{ readOnly: true }}
						className={"d-none"}
						onChange={(fieldName, value, pIsValid) => {
							handleInputChange(fieldName, value, pIsValid);
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};
export default CreditCardAddForm;
