import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#ContractsCreate
 */

const ContractsCreateService = {
	/**
	 *
	 * @param {ContractsCreateView} ContractsCreateView
	 * @see /docs/api/index.html#jump-ContractsCreate-View
	 * @returns
	 */
	index: (ContractsCreateView) => {
		return ApiService.post(
			"/contracts/contract-create",
			ContractsCreateView
		).catch((error) => {
			console.log(error);
			return error;
		});
	},

	delete: (tmpContractId) => {
		return ApiService.post("/contracts/contract-create", {
			step: "step_0",
			action: "delete",
			tmp_contracts_id: tmpContractId,
		}).catch((error) => {
			console.log(error);
			return error;
		});
	},

	verifyPersonnel: (formDataObject, tmpContractId) => {
		const payload = {
			step: "step_2",
			tmp_contracts_id: tmpContractId,
			data: {
				personnel: formDataObject,
			},
		};
		return ApiService.post("/contracts/contract-create", payload).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	verifyAddress: (formDataObject, tmpContractId) => {
		const payload = {
			step: "step_2",
			tmp_contracts_id: tmpContractId,
			data: {
				address: formDataObject,
			},
		};
		return ApiService.post("/contracts/contract-create", payload).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	verifyEmail: (email, tmpContractId) => {
		const payload = {
			step: "step_2",
			tmp_contracts_id: tmpContractId,
			data: {
				email: {
					email_address: email,
				},
			},
		};
		return ApiService.post("/contracts/contract-create", payload).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	verifyEmailCode: (email, code, tmpContractId) => {
		const payload = {
			step: "step_2",
			tmp_contracts_id: tmpContractId,
			data: {
				email: {
					email_address: email,
					email_address_code: code,
				},
			},
		};
		return ApiService.post("/contracts/contract-create", payload).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	sendIdLink: (verificationType, contact, tmpContractId) => {
		const payload = {
			step: "step_3",
			tmp_contracts_id: tmpContractId,
			data: {
				selector: {
					id: verificationType,
					value: contact,
				},
			},
		};
		return ApiService.post("/contracts/contract-create", payload).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	recaptureImage(action, tmpContractId) {
		const payload = {
			step: "step_3",
			tmp_contracts_id: tmpContractId,
			action: action,
		};
		return ApiService.post("/contracts/contract-create", payload).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	// /**
	//  * data filter by ID
	//  * @param {int} id
	//  * @see /docs/api/index.html#jump-ContractsCreate-FetchById
	//  * @returns
	//  */
	// fetchById: (id) => {
	// 	return ApiService.get("/contracts/contract-create/fetch-by-id", { id: id }).catch(
	// 		(error) => {
	// 			console.log(error);
	// 		}
	// 	);
	// },

	// /**
	//  * Update service
	//  * @see /docs/api/index.html#jump-ContractsCreate-Update
	//  * @param json data {contractsCreateId : 1, name: 'xxxx', address_1: 'ADD1' ...}
	//  * @returns
	//  */
	// update: (data) => {
	// 	return ApiService.post("/contracts/contract-create/update", data).catch((error) => {
	// 		console.log(error);
	// 	});
	// },

	// /**
	//  *
	//  * @param {ContractsCreateCreate} ContractsCreateCreate
	//  * @see /docs/api/index.html#jump-ContractsCreate-Create
	//  * @returns
	//  */
	// create: (ContractsCreateCreate) => {
	// 	return ApiService.post("/contracts/contract-create/create", ContractsCreateCreate).catch((error) => {
	// 		console.log(error);
	// 	});
	// },
};

export default ContractsCreateService;
