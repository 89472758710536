import { MdChevronLeft, MdChevronRight, MdClose } from "react-icons/md";

export const contractsCreateStep0Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		start_question: {
			type: "typography",
			text: "Do you want to create a new contract or pickup where you left off previously?",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		create_or_resume: {
			type: "radio",
			name: "create_or_resume",
			value: "",
			label: "Contract Options",
			required: true,
			readOnly: false,
			upperCase: true,
			options: [
				{ label: "Create New Contract", value: "create" },
				{ label: "Resume Contract", value: "resume" },
			],
			style: {
				textTransform: "uppercase",
			},
			displayAsSwitches: true,
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "start_question",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-lg-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "radio",
									name: "create_or_resume",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{ type: "div", name: "conditionalElementPlaceholder" },
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Cancel",
			align: "left",
			color: "default",
			action: "handleCancel",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep1Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		tmp_contracts_id: {
			type: "hidden",
			label: "Tmp Contract ID",
			value: "",
			required: true,
			readOnly: true,
			upperCase: false,
			style: {
				display: "none",
			},
		},
		step_title: {
			type: "typography",
			text: "Contract Security",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		lock_title: {
			type: "typography",
			text: "Do you want to secure this contract with a device lock?",
			variant: "h5",
			className:
				"text-left py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		lock_enabled: {
			type: "switch",
			label: "Enable",
			value: false,
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		abp_title: {
			type: "typography",
			text: "Do you want to secure this contract with Automatic Bill Pay?",
			variant: "h5",
			className:
				"text-left py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		abp_enabled: {
			type: "switch",
			label: "Enable",
			value: false,
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		device_identifier: {
			type: "input",
			label: "Device Identifier",
			value: "",
			required: false,
			readOnly: false,
			upperCase: false,
			style: {
				textTransform: "uppercase",
			},
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "hidden",
									name: "tmp_contracts_id",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "lock_title",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-6 offset-lg-3",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "switch",
									name: "lock_enabled",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "abp_title",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-6 offset-lg-3",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "switch",
									name: "abp_enabled",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-8 offset-lg-2",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "input",
									name: "device_identifier",
									class: "col-md-12",
								},
							],
						},
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep2Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Customer Information",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		first_name: {
			type: "input",
			label: "First Name",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		last_name: {
			type: "input",
			label: "Last Name",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		phone_number: {
			type: "tel",
			label: "Phone Number",
			value: "",
			required: true,
			readOnly: false,
			upperCase: false,
			style: {
				textTransform: "uppercase",
			},
		},
		date_of_birth: {
			type: "date",
			label: "Date of Birth",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		ssn_last_four: {
			type: "number",
			label: "Last 4 SSN",
			value: "",
			required: true,
			readOnly: false,
			upperCase: false,
			minLength: 4,
			maxLength: 4,
			style: {
				textTransform: "uppercase",
			},
		},
		address_1: {
			type: "input",
			label: "Address",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		address_2: {
			type: "input",
			label: "Suite",
			value: "",
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		zip: {
			type: "zip",
			label: "Zip",
			value: "",
			required: true,
			readOnly: false,
			style: {
				textTransform: "uppercase",
			},
		},
		city: {
			type: "text",
			label: "City",
			value: "",
			required: true,
			readOnly: true,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		state: {
			type: "text",
			label: "State",
			value: "",
			required: true,
			readOnly: true,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		// newsletter_enabled: {
		// 	type: "checkbox",
		// 	label: "Yes, sign me up! By checking this box, I agree and I want to receive news, offers, payment reminders, and account alerts from RTO Mobile & RTO MOBILE - 123 EXAMPLE ST, including by email, phone, sms, and mail to the contact information I am submitting. I consent to RTO Mobile & RTO MOBILE - 123 EXAMPLE ST, its affiliates and service provides process my personal data for these purposes as described in the Privacy Policy. I understand that I can withdraw my consent at any time.",
		// 	value: false,
		// 	required: true,
		// 	readOnly: false,
		// 	upperCase: false,
		// 	// style: {
		// 	// 	textTransform: "uppercase",
		// 	// },
		// },
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row",
							items: [
								{
									type: "div",
									class: "col-12",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "typography",
													name: "step_title",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-4",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "first_name",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-4",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "last_name",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-4",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "phone",
													name: "phone_number",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "date",
													name: "date_of_birth",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "ssn_last_four",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "address_1",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "input",
													name: "address_2",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-md-12",
									items: [
										{
											type: "div",
											class: "row",
											items: [
												{
													type: "zip",
													name: "zip",
													targetCity: "city",
													targetState: "state",
													class: "col-md-4 mb-3",
												},
												{
													type: "input",
													name: "city",
													class: "col-md-4 mb-3",
												},
												{
													type: "input",
													name: "state",
													class: "col-md-4 mb-3",
												},
											],
										},
									],
								},
								{
									type: "div",
									name: "conditionalElementPlaceholder1",
								},
								{
									type: "div",
									name: "conditionalElementPlaceholder2",
								},
								// {
								// 	type: "div",
								// 	// class: "col-6 offset-lg-3",
								// 	class: "col-12",
								// 	items: [
								// 		{
								// 			type: "div",
								// 			class: "row mb-3",
								// 			items: [
								// 				{
								// 					type: "checkbox",
								// 					name: "newsletter_enabled",
								// 					class: "col-md-12",
								// 				},
								// 			],
								// 		},
								// 	],
								// },
							],
						},
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep3Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "ID Verification",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		id_type: {
			type: "select",
			label: "ID Type",
			value: "",
			required: false,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
			options: [
				{ id: "", name: "Select ID Type..." },
				{ id: "3", name: "Federal Issued ID" },
				{ id: "6", name: "Matricula ID" },
				{ id: "4", name: "Passport" },
				{ id: "2", name: "State Issued Drivers License" },
				{ id: "1", name: "State Issued ID" },
				{ id: "5", name: "Other" },
			],
		},
		id_number: {
			type: "input",
			label: "ID Number",
			value: "",
			required: false,
			readOnly: false,
			upperCase: false,
			style: {
				textTransform: "uppercase",
			},
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "select",
									name: "id_type",
									class: "col-md-6",
								},
								{
									type: "input",
									name: "id_number",
									class: "col-md-6",
								},
							],
						},
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									text: "How would you like to receive a link to upload images of the customers ID?",
									variant: "p",
									className:
										"text-center py-3 font-light font-sans",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder1" },
						{ type: "div", name: "conditionalElementPlaceholder2" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep4Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Contract Details",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
		retail_value: {
			type: "number",
			label: "Retail Value",
			value: "",
			prefix: "$",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		retail_value_caption: {
			type: "typography",
			text: "Retail max value: $0.00",
			variant: "caption",
			className: "text-center font-light font-sans",
		},
		down_payment_amount: {
			type: "number",
			label: "Down Payment",
			value: "",
			suffix: "%",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		down_payment_amount_caption: {
			type: "typography",
			text: "$0.00 min 0.00% | $0.00 max 70.00% | $0.00 default 20.00%",
			variant: "caption",
			className: "text-center font-light font-sans",
		},
		rental_factor: {
			type: "number",
			label: "Rental Factor",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		rental_factor_caption: {
			type: "typography",
			text: "0.00 min | 0.00 max | 0.00 default",
			variant: "caption",
			className: "text-center font-light font-sans",
		},
		total_contract_value: {
			type: "number",
			label: "Total Contract Value",
			value: "11.00",
			prefix: "$",
			required: true,
			readOnly: false,
			decimalLimit: 2,
			integerOnly: false,
			minValue: 0,
			maxValue: 2000,
		},
		merchandise_condition: {
			type: "select",
			label: "Select Merchandise Condition",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
			options: [],
		},
		merch_description: {
			type: "textarea",
			label: "Merchandise Description",
			value: "",
			required: true,
			readOnly: false,
		},
		payoff_early_policy_id: {
			type: "select",
			label: "Early Payoff Options",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
			options: [],
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "number",
													name: "retail_value",
													class: "col-md-12",
												},
												{
													type: "typography",
													name: "retail_value_caption",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "number",
													name: "down_payment_amount",
													class: "col-md-12",
												},
												{
													type: "typography",
													name: "down_payment_amount_caption",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "number",
													name: "rental_factor",
													class: "col-md-12",
												},
												{
													type: "typography",
													name: "rental_factor_caption",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "number",
													name: "total_contract_value",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "select",
													name: "merchandise_condition",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-6",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "select",
													name: "payoff_early_policy_id",
													class: "col-md-12",
												},
											],
										},
									],
								},
								{
									type: "div",
									class: "col-12",
									items: [
										{
											type: "div",
											class: "row mb-3",
											items: [
												{
													type: "textarea",
													name: "merch_description",
													class: "col-md-12",
												},
											],
										},
									],
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep5Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Contract Summary",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep6Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Payment Collection",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder1" },
						{ type: "div", name: "conditionalElementPlaceholder2" },
						{ type: "div", name: "conditionalElementPlaceholder3" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep7Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "ABP Enrollment",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder1" },
						{ type: "div", name: "conditionalElementPlaceholder2" },
						{ type: "div", name: "conditionalElementPlaceholder3" },
						{ type: "div", name: "conditionalElementPlaceholder4" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const contractsCreateStep8Definition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		step_title: {
			type: "typography",
			text: "Contract Completion",
			variant: "h5",
			className:
				"text-center py-3 text-3xl text-cyan-800 font-light font-sans",
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-10 offset-lg-1",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "typography",
									name: "step_title",
									class: "col-md-12",
								},
							],
						},
						{ type: "div", name: "conditionalElementPlaceholder1" },
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "button",
			label: "Back",
			align: "left",
			color: "default",
			action: "handleBack",
			disableElevation: true,
			startIcon: <MdChevronLeft />,
		},
		{
			type: "button",
			label: "Cancel & Delete",
			align: "center",
			color: "default",
			action: "handleDelete",
			disableElevation: true,
			startIcon: <MdClose style={{ color: "#ef4444" }} />,
		},
		{
			type: "submit",
			label: "Continue",
			align: "right",
			color: "primary",
			disableElevation: true,
			endIcon: <MdChevronRight />,
		},
	],
};

export const stateOptions = [
	{ id: "1", name: "Alabama" },
	{ id: "2", name: "Alaska" },
	{ id: "3", name: "Arizona" },
	{ id: "4", name: "Arkansas" },
	{ id: "5", name: "California" },
	{ id: "6", name: "Colorado" },
	{ id: "7", name: "Connecticut" },
	{ id: "8", name: "Delaware" },
	{ id: "51", name: "District of Columbia" },
	{ id: "9", name: "Florida" },
	{ id: "10", name: "Georgia" },
	{ id: "11", name: "Hawaii" },
	{ id: "12", name: "Idaho" },
	{ id: "13", name: "Illinois" },
	{ id: "14", name: "Indiana" },
	{ id: "15", name: "Iowa" },
	{ id: "16", name: "Kansas" },
	{ id: "17", name: "Kentucky" },
	{ id: "18", name: "Louisiana" },
	{ id: "19", name: "Maine" },
	{ id: "20", name: "Maryland" },
	{ id: "21", name: "Massachusetts" },
	{ id: "22", name: "Michigan" },
	{ id: "23", name: "Minnesota" },
	{ id: "24", name: "Mississippi" },
	{ id: "25", name: "Missouri" },
	{ id: "26", name: "Montana" },
	{ id: "27", name: "Nebraska" },
	{ id: "28", name: "Nevada" },
	{ id: "29", name: "New Hampshire" },
	{ id: "30", name: "New Jersey" },
	{ id: "31", name: "New Mexico" },
	{ id: "32", name: "New York" },
	{ id: "33", name: "North Carolina" },
	{ id: "34", name: "North Dakota" },
	{ id: "35", name: "Ohio" },
	{ id: "36", name: "Oklahoma" },
	{ id: "37", name: "Oregon" },
	{ id: "38", name: "Pennsylvania" },
	{ id: "39", name: "Rhode Island" },
	{ id: "40", name: "South Carolina" },
	{ id: "41", name: "South Dakota" },
	{ id: "42", name: "Tennessee" },
	{ id: "43", name: "Texas" },
	{ id: "44", name: "Utah" },
	{ id: "45", name: "Vermont" },
	{ id: "46", name: "Virginia" },
	{ id: "47", name: "Washington" },
	{ id: "48", name: "West Virginia" },
	{ id: "49", name: "Wisconsin" },
	{ id: "50", name: "Wyoming" },
];
