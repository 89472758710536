import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const CustomSwitchComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(
			value === "true" || value === true
		);
		const [isValid, setIsValid] = useState(true);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(value === "true" || value === true);
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				validate();
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useImperativeHandle(ref, () => ({
			validate: () => validate(),
			isTouched: () => isTouched,
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			setInternalValue(newValue);
			onChange(name, newValue, isValid);
		};

		const validate = () => {
			const fieldInfo = getFieldInfo();
			if (fieldInfo.required && !internalValue) {
				setIsValid(false);
				setErrorMessage("This field is required.");
				setIsTouched(true);
				// onChange(name, internalValue, false);
				return false;
			} else {
				setIsValid(true);
				setErrorMessage("");
				// onChange(name, internalValue, true);
				return true;
			}
		};

		const handleFocus = () => {
			setIsTouched(true);
			validate();
		};

		const getFieldInfo = () => {
			return {
				required: Boolean(type && type.required),
				readOnly: type && type.readOnly,
				upperCase: type && type.upperCase,
			};
		};

		return (
			<div className={className}>
				<FormControl error={!isValid && isTouched} className="w-full">
					<FormControlLabel
						control={
							<Switch
								id={name}
								checked={internalValue}
								onChange={(e) => handleChange(e.target.checked)}
								onFocus={handleFocus}
								onBlur={onBlur}
								name={name}
								disabled={getFieldInfo().readOnly}
								{...otherProps}
								inputRef={inputRef}
							/>
						}
						label={
							getFieldInfo().upperCase
								? label.toUpperCase()
								: label
						}
					/>
					{!isValid && isTouched && (
						<FormHelperText>{errorMessage}</FormHelperText>
					)}
				</FormControl>
			</div>
		);
	}
);

export default CustomSwitchComponent;
