import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import TextField from "@mui/material/TextField";

/**
 * @doc when we need to copy the content to another textbox
 * [
 *	'type' => 'input',
 *	'name' => 'address_1',
 *	'class' => 'col-md-12',
 *	'targetTextComponent' => 'address_2',  // Optional property to copy the content to another textbox
 * ],
 */

const CustomInputComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			targetTextComponent,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(value);
		const [isValid, setIsValid] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const [isMounted, setIsMounted] = useState(false); // New state to track if component is mounted
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(value);
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				// console.log("Validating field on change.");
				validate();
			} else {
				// console.log("Skipping validation on initial load.");
				setIsMounted(true);
			}
		}, [internalValue]);

		useImperativeHandle(ref, () => ({
			validate: () => validate(),
			isTouched: () => isTouched,
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (newValue) => {
			setInternalValue(newValue);
			onChange(name, newValue, isValid);
		};

		const validate = () => {
			const fieldInfo = getFieldInfo();

			let trimmedValue = internalValue;
			setInternalValue(trimmedValue);

			if (fieldInfo.readOnly) {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, trimmedValue, true);
				return true;
			}

			// Check minLength and maxLength only if the field is not empty
			if (trimmedValue !== "") {
				// Check minLength
				if (
					fieldInfo.minLength &&
					trimmedValue.length < fieldInfo.minLength
				) {
					setIsValid(false);
					setErrorMessage(
						`Minimum length is ${fieldInfo.minLength} characters.`
					);
					setIsTouched(true);
					onChange(name, trimmedValue, false);
					return false;
				}

				// Check maxLength
				if (
					fieldInfo.maxLength &&
					trimmedValue.length > fieldInfo.maxLength
				) {
					setIsValid(false);
					setErrorMessage(
						`Maximum length is ${fieldInfo.maxLength} characters.`
					);
					setIsTouched(true);
					onChange(name, trimmedValue, false);
					return false;
				}
			}

			// Check required
			if (fieldInfo.required && trimmedValue === "") {
				setIsValid(false);
				setErrorMessage("This field is required.");
				setIsTouched(true);
				onChange(name, trimmedValue, false);

				if (targetTextComponent) {
					onChange(targetTextComponent, trimmedValue, true);
				}
				return false;
			}

			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);

			if (targetTextComponent) {
				onChange(targetTextComponent, trimmedValue, true);
			}
			return true;
		};

		const handleFocus = () => {
			setIsTouched(true);
			validate();
		};

		const getFieldInfo = () => {
			return {
				required: Boolean(type && type.required),
				minLength: type && type.minLength,
				maxLength: type && type.maxLength,
				readOnly: type && type.readOnly,
				upperCase: type && type.upperCase,
			};
		};

		return (
			<div className={`${className}`}>
				<TextField
					id={name}
					label={`${label}`}
					variant="standard"
					size="small"
					fullWidth
					type={type && type.type}
					value={internalValue}
					onChange={(e) => handleChange(e.target.value)}
					onFocus={handleFocus}
					onBlur={onBlur}
					error={!isValid && isTouched}
					helperText={!isValid && isTouched && errorMessage}
					className={getFieldInfo().readOnly ? "readonly-text" : ""}
					inputProps={{
						readOnly: getFieldInfo().readOnly,
						style: {
							textTransform: getFieldInfo().upperCase
								? "uppercase"
								: "none",
						},
					}}
					{...otherProps}
					inputRef={inputRef}
				/>
			</div>
		);
	}
);

export default CustomInputComponent;
