import React from 'react';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";

const theme = createTheme({
	palette: {
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

const TmpContractsIndexTable = ({ tmpContracts, onDelete }) => {
    const columns = [
		{
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <IconButton component={Link} to={`/view/${tmpContracts[dataIndex].id}`}>
                                <VisibilityIcon color="primary" />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteAction(tmpContracts[dataIndex].id)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </div>
                    );
                }
            }
        },
		{
			name: "id",
			label: "ID",
		},
        {
            name: "struct_store_name",
            label: "Store",
        },
		{
            name: "serial_number",
            label: "Serial #",
        },
		{
            name: "imei",
            label: "IMEI",
        },
        {
            name: "customer_name",
            label: "Customer",
        },
		{
			name: "step",
			label: "Step",
		},
        {
            name: "created",
            label: "Created",
        },
    ];

    const options = {
        filterType: 'textField',
        responsive: 'standard',
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: 'none',
		elevation: 0,
    };

	const handleDeleteAction = (tmpContractId) => {
		confirmAlert({
			title: "Are you sure?",
			message: "Are you sure you want to delete this temporary contract? This action cannot be reversed.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						// deleteRecord(tmpContractId);
						onDelete(tmpContractId);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

    return (
        <ThemeProvider theme={theme}>
            <MUIDataTable
                title={"Temporary Contracts"}
                data={tmpContracts}
                columns={columns}
                options={options}
            />
        </ThemeProvider>
    );
};

export default TmpContractsIndexTable;