import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const CashPaymentBalanceCalculatorForm = ({
	paymentAmount,
	setIsSubmitDisabled,
}) => {
	const [errorMessage, setErrorMessage] = useState("");
	const [cashBalance, setCashBalance] = useState("");
	const [cashBalanceErrorMessage, setCashBalanceErrorMessage] = useState("");
	const [tenderValue, setTenderValue] = useState("");
	const [cashInput, setCashInput] = useState("");
	const inputRef = useRef(null); // Create a ref for the input field

	useEffect(() => {
		setIsSubmitDisabled(true);
	}, []);

	const calculateCashChange = (event) => {
		if (parseFloat(event.target.value) >= parseFloat(paymentAmount)) {
			let balance =
				parseFloat(event.target.value) - parseFloat(paymentAmount);
			setCashBalance(balance.toFixed(2));
			setCashBalanceErrorMessage("");
			setIsSubmitDisabled(false);
		} else {
			setCashBalanceErrorMessage(
				"Amount received must be greater than or equal to total"
			);
			setIsSubmitDisabled(true);
		}
	};

	return (
		<div>
			<Grid
				item
				xs={10}
				marginTop={2}
				style={{
					textAlign: "right",
				}}
			>
				Amount Tendered:
				{cashBalanceErrorMessage && (
					<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required">
						{cashBalanceErrorMessage}
					</p>
				)}
			</Grid>
			<Grid
				item
				xs={2}
				marginTop={1}
				paddingLeft={1}
				style={{ textAlign: "right" }}
			>
				<TextField
					id="outlined-basic"
					variant="standard"
					style={{ textAlign: "right" }}
					value={cashInput}
					InputLabelProps={{
						shrink: true,
					}}
					inputRef={inputRef}
					// autoFocus
					inputProps={{
						// Adding inputProps to align text inside the input
						style: { textAlign: "right" },
					}}
					onBlur={calculateCashChange}
					onChange={(e) => {
						setCashInput(e.target.value);
					}}
					error={cashBalanceErrorMessage !== ""}
				/>
			</Grid>
			<Grid item xs={10} marginTop={1} style={{ textAlign: "right" }}>
				Change Due:
			</Grid>
			<Grid item xs={2} marginTop={1} style={{ textAlign: "right" }}>
				${Number(cashBalance).toFixed(2).toLocaleString()}
			</Grid>
		</div>
	);
};

export default CashPaymentBalanceCalculatorForm;
