import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import DynamicForm from "../formComponents/DynamicForm";
import SessionService from "../../service/SessionService";
import ContractsCreateService from "../../service/contractsCreate/ContractsCreateService";
import RtoEmailVerificationField from "../formComponents/RtoEmailVerificationField";
import RtoVerificationField from "../formComponents/RtoVerificationField";
import {
	contractsCreateStep0Definition,
	contractsCreateStep1Definition,
	contractsCreateStep2Definition,
	contractsCreateStep3Definition,
	contractsCreateStep4Definition,
	contractsCreateStep5Definition,
	contractsCreateStep6Definition,
	contractsCreateStep7Definition,
	contractsCreateStep8Definition,
	stateOptions,
} from "../formComponents/rtoDynamicForm/definitions/contractsCreateFormDefinition";
import MultiStepFormStepper from "../formComponents/rtoDynamicForm/MultiStepFormStepper";
import TmpContractsIndexTable from "../common/TmpContractsIndexTable";
import PaymentTermsForm from "../backend/contracts/PaymentTermsForm";
import ContractSummary from "../../components/backend/contracts/ContractSummary";
import SelectPaymentMethodsForm from "../formComponents/SelectPaymentMethodsForm";
import CashPaymentBalanceCalculatorForm from "../formComponents/CashPaymentBalanceCalculatorForm";
import CreditCardAddForm from "../formComponents/CreditCardAddForm";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Button } from "@mui/material";
import { MdCheck } from "react-icons/md";
import TopModal from "../common/TopModal";
import { parseNumeric } from "../../helpers/Utils";

const steps = [
	{ id: 1, label: "Contract Security" },
	{ id: 2, label: "Customer Information" },
	{ id: 3, label: "ID Verification" },
	{ id: 4, label: "Contract Details" },
	{ id: 5, label: "Contract Summary" },
	{ id: 6, label: "Payment Collection" },
	{ id: 7, label: "ABP Enrollment" },
	{ id: 8, label: "Contract Completion" },
];

const stepDefinitions = [
	contractsCreateStep0Definition,
	contractsCreateStep1Definition,
	contractsCreateStep2Definition,
	contractsCreateStep3Definition,
	contractsCreateStep4Definition,
	contractsCreateStep5Definition,
	contractsCreateStep6Definition,
	contractsCreateStep7Definition,
	contractsCreateStep8Definition,
];

function ContractsCreateForm({ loading, setLoading }) {
	const navigate = useNavigate();
	const formRef = useRef(null);

	const [step, setStep] = useState(0);
	const [nextStep, setNextStep] = useState();

	const [tmpContracts, setTmpContracts] = useState([]);
	const [tmpContractId, setTmpContractId] = useState(null);

	const [extraContractCreateData, setExtraContractCreateData] = useState({
		step: nextStep,
		action: "new",
	});
	const [sendEmptyContractCreateData, setSendEmptyContractCreateData] =
		useState(false);

	const [
		definitionContractCreateOverride,
		setDefinitionContractCreateOverride,
	] = useState(_.cloneDeep(contractsCreateStep0Definition));

	const [formState, setFormState] = useState({});
	const formStateRef = useRef(formState);
	const [canContinue, setCanContinue] = useState(true);

	// step 1 state
	const [step1EnabledLockRequired, setStep1EnabledLockRequired] =
		useState(false);
	const [step1EnabledLockEnabled, setStep1EnabledLockEnabled] =
		useState(false);
	const [step1EnabledAbpRequired, setStep1EnabledAbpRequired] =
		useState(false);
	const [step1EnabledAbpEnabled, setStep1EnabledAbpEnabled] = useState(false);

	// step 2 state
	const [step2IsVerifyAddressVisible, setStep2IsVerifyAddressVisible] =
		useState(false);
	const [
		step2IsEmailVerificationVisible,
		setStep2IsEmailVerificationVisible,
	] = useState(false);
	const step2ReadOnlyFields = [
		"first_name",
		"last_name",
		"phone_number",
		"date_of_birth",
		"ssn_last_four",
		"address_1",
		"address_2",
		"zip",
		"city",
		"state",
	];
	const [step2IsEmailSent, setStep2IsEmailSent] = useState(false);
	const [step2EmailCode, setStep2EmailCode] = useState(null);
	const [step2IsModalOpen, setStep2IsModalOpen] = useState(false);
	const [step2EnteredAddress, setStep2EnteredAddress] = useState({});
	const [step2FoundAddress, setStep2FoundAddress] = useState({});
	const [
		step2IsEmailVerificationDisabled,
		setStep2IsEmailVerificationDisabled,
	] = useState(false);

	// step 3 state
	const [
		step3StoreRequireIdVerification,
		setStep3StoreRequireIdVerification,
	] = useState(false);
	const [step3TriggerFormUpdate, setStep3TriggerFormUpdate] = useState(false);
	const [step3IdType, setStep3IdType] = useState(undefined);
	const [step3Selector, setStep3Selector] = useState({});
	const [step3IsLinkSent, setStep3IsLinkSent] = useState(false);
	const [step3ImageRefreshInterval, setStep3ImageRefreshInterval] =
		useState(null);
	const [step3ImageCount, setStep3ImageCount] = useState(0);
	const [step3Image1, setStep3Image1] = useState(null);
	const [step3Image2, setStep3Image2] = useState(null);
	const [step3Image3, setStep3Image3] = useState(null);

	// step 4 state
	const [step4TriggerFormUpdate, setStep4TriggerFormUpdate] = useState(false);

	// step 6 state
	const [creditCardInfo, setCreditCardInfo] = useState({
		first_name: "",
		last_name: "",
		month: " ",
		year: " ",
		cvv: "",
		billing_address: "",
		zip: "",
		city: "",
		state: "",
		token: "",
	});

	// admin state for bypassing form submission
	const [createResetCounter, setCreateResetCounter] = useState(0);
	const [bypassFormSubmission, setBypassFormSubmission] = useState(true); // Add a state to control bypass

	const handleStepClick = async (newStep) => {
		if (bypassFormSubmission) {
			setLoading(true);
			setStep(newStep);
			setNextStep("step_" + (newStep + 1));
			setDefinitionContractCreateOverride(stepDefinitions[newStep]);

			if (newStep === 1) {
				updateContractCreateDefinitionWithValues(
					[
						step1EnabledLockRequired,
						step1EnabledLockEnabled,
						step1EnabledAbpRequired,
						step1EnabledAbpEnabled,
					],
					formState,
					_.cloneDeep(contractsCreateStep1Definition)
				);
			}

			// @todo : We need to integrate this logic into next Step action
			if (newStep === 4) {
				// @todo replace the id with tmp_contracts_id
				// fetchTmpContractData(tmpContractId, "step_4");
				// fetchTmpContractData(18345, "step_4");
				// updateContractCreateDefinitionWithValues(
				// 	[],
				// 	formState,
				// 	_.cloneDeep(contractsCreateStep4Definition)
				// );
			}
			setTimeout(() => {
				setLoading(false);
			}, 200);
		}
	};

	const [tmpContractCreateData, setTmpContractCreateData] = useState({});

	const fetchTmpContractData = async (id, step) => {
		setLoading(true);
		const response = await ContractsCreateService.index({
			tmp_contracts_id: id,
			step: step,
		});
		if (response && response.data) {
			setTmpContractCreateData(response.data.data || []);
		}
		setLoading(false);
	};

	// shared logic for all steps
	const handleFormChange = (updatedFormState) => {
		setFormState(updatedFormState);
		formStateRef.current = updatedFormState; // Update the ref whenever formState changes
	};

	useEffect(() => {
		formStateRef.current = formState; // Update the ref whenever formState changes
	}, [formState]);

	const actionHandlers = {
		handleCancel: () => navigate("/dashboard"),
		handleDelete: () => handleDeleteAction(tmpContractId),
		handleAnotherAction: () => {
			// console.log("Another action triggered");
		},
	};

	const renderConditionalElement = () => {
		if (step === 0) {
			if (formState.create_or_resume?.value === "resume") {
				return {
					conditionalElementPlaceholder: (
						<>
							{tmpContracts.length > 0 && (
								<TmpContractsIndexTable
									tmpContracts={tmpContracts}
									onDelete={deleteTmpContractRecord}
								/>
							)}
						</>
					),
				};
			}
		}

		if (step === 2) {
			return {
				conditionalElementPlaceholder1: step2IsVerifyAddressVisible && (
					<>
						<br />
						<div className="flex flex-col items-center justify-center">
							<Button
								variant="contained"
								color="primary"
								onClick={step2HandleAddressSubmit}
								disableElevation
								sx={{ height: "56px" }}
								startIcon={<MdCheck />}
							>
								VERIFY ADDRESS
							</Button>
							<br />
						</div>
					</>
				),
				conditionalElementPlaceholder2:
					step2IsEmailVerificationVisible && (
						<RtoEmailVerificationField
							handleEmailSubmit={step2HandleEmailSubmit}
							isEmailSent={step2IsEmailSent}
							setIsEmailSent={setStep2IsEmailSent}
							handleCodeSubmit={step2HandleCodeSubmit}
							disabled={step2IsEmailVerificationDisabled}
							defaultCode={step2EmailCode}
						/>
					),
			};
		}

		if (step === 3) {
			return {
				conditionalElementPlaceholder1: (
					<RtoVerificationField
						handleContactSubmit={step3HandleContactSubmit}
						isLinkSent={step3IsLinkSent}
						setIsLinkSent={setStep3IsLinkSent}
						verificationType="email"
						selector={step3Selector}
					/>
				),
				conditionalElementPlaceholder2: (
					<div>
						<div className="text-zinc-800 float-left text-sm pb-7 px-3 pt-3 w-full bg-slate-100">
							<div
								className="items-center flex-wrap justify-start py-4 px-3 text-center flex w-auto m-auto"
								id="div-1"
							>
								<div className="items-center justify-start flex-col flex">
									<div className="w-52 min-h-[9.8rem] max-w-full my-1 mx-3 border-1 bg-slate-200/[0.5] border-slate-300 border-solid rounded-md overflow-hidden">
										{step3Image1 && (
											<img
												src={`data:image/jpeg;base64,${step3Image1}`}
												alt="I.D. Front"
												className="w-full h-full object-cover rounded"
											/>
										)}
									</div>

									<div className="text-slate-500/[0.45] font-normal">
										I.D. Front
									</div>

									{step3Image1 && (
										<Button
											onClick={() =>
												step3RecaptureImage(
													"recapture_image_1"
												)
											}
											variant="contained"
											disableElevation
											sx={{
												backgroundColor: "#e11d48",
												fontWeight: 400,
												color: "white",
												cursor: "pointer",
												padding: "0 12px",
												height: "20px",
												marginTop: "8px",
												borderRadius: "12px",
												"&:hover": {
													backgroundColor: "#be123c",
												},
											}}
										>
											Recapture
										</Button>
									)}
								</div>

								<div className="items-center flex-col flex">
									<div className="w-52 min-h-[9.8rem] max-w-full my-1 mx-3 border-1 bg-slate-200/[0.5] border-slate-300 border-solid rounded-md overflow-hidden">
										{step3Image2 && (
											<img
												src={`data:image/jpeg;base64,${step3Image2}`}
												alt="I.D. Back"
												className="w-full h-full object-cover rounded"
											/>
										)}
									</div>

									<div className="text-slate-500/[0.45] font-normal">
										I.D. Back
									</div>

									{step3Image2 && (
										<Button
											onClick={() =>
												step3RecaptureImage(
													"recapture_image_2"
												)
											}
											variant="contained"
											disableElevation
											sx={{
												backgroundColor: "#e11d48",
												fontWeight: 400,
												color: "white",
												cursor: "pointer",
												padding: "0 12px",
												height: "20px",
												marginTop: "8px",
												borderRadius: "12px",
												"&:hover": {
													backgroundColor: "#be123c",
												},
											}}
										>
											Recapture
										</Button>
									)}
								</div>

								<div className="items-center flex-col flex">
									<div className="w-52 min-h-[9.8rem] max-w-full my-1 mx-3 border-1 bg-slate-200/[0.5] border-slate-300 border-solid rounded-md overflow-hidden">
										{step3Image3 && (
											<img
												src={`data:image/jpeg;base64,${step3Image3}`}
												alt="Customer"
												className="w-full h-full object-cover rounded"
											/>
										)}
									</div>

									<div className="text-slate-500/[0.45] font-normal">
										Customer
									</div>

									{step3Image3 && (
										<Button
											onClick={() =>
												step3RecaptureImage(
													"recapture_image_3"
												)
											}
											variant="contained"
											disableElevation
											sx={{
												backgroundColor: "#e11d48",
												fontWeight: 400,
												color: "white",
												cursor: "pointer",
												padding: "0 12px",
												height: "20px",
												marginTop: "8px",
												borderRadius: "12px",
												"&:hover": {
													backgroundColor: "#be123c",
												},
											}}
										>
											Recapture
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				),
			};
		}

		if (step === 4) {
			return {
				conditionalElementPlaceholder: (
					<PaymentTermsForm
						loading={loading}
						setLoading={setLoading}
						availablePlans={tmpContractCreateData?.available_plans}
						planSelectedValue={
							tmpContractCreateData?.fields?.lease_length
						}
						leaseTypeId={
							tmpContractCreateData?.fields?.lease_type_id
						}
						planSelectedDay={
							tmpContractCreateData?.fields?.lease_day
						}
						planSelectDays={
							tmpContractCreateData?.selects?.lease_day || []
						}
						planSelectedDate={
							tmpContractCreateData?.fields?.lease_date
						}
						planSelectDates={
							tmpContractCreateData?.selects?.lease_date || []
						}
						paymentTermOnChange={step4PaymentTermOnChange}
						PlanDayOnChange={step4PlanDayOnChange}
						PlanDateOnChange={step4PlanDateOnChange}
						// viewOnly={viewOnly}
					/>
				),
			};
		}

		if (step === 5) {
			const step5DummyData = {
				balance_after_payment: "430.50",
				invoices: {
					data: {
						invoice_count: "7",
						invoices: [
							{
								date: "2024-07-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-08-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-09-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-10-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-11-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2024-12-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "61.50",
							},
							{
								date: "2025-01-23",
								net: "61.50",
								tax_rate: "6.00",
								tax: "3.69",
								fee: "0.00",
								total: "65.19",
								running_balance: "123.00",
							},
						],
					},
				},
			};

			return {
				conditionalElementPlaceholder: (
					<ContractSummary paymentPlans={step5DummyData} />
				),
			};
		}

		if (step === 6) {
			const step6DummyData = {
				payment_methods: {
					CC9256: {
						id: 9256,
						name: "CC9256",
						payment_name: "VISA xxxx xxxx xxxx 0919 Exp 06/27",
						integration: "CC",
						profile_id: "19215101388929974508",
						acct_id: "1",
						token: "9417647321810919",
						accountholder_name: "NAYELI SALAZAR",
						account_type: "VISA",
						cc_last_four: "0919",
						cc_expiration: "0627",
						default_account: "Y",
						tokenizer_url: null,
					},
					CASH: {
						id: null,
						payment_name: "Cash Payment",
						integration: "CASH",
						profile_id: null,
						acct_id: null,
						token: null,
						accountholder_name: null,
						account_type: null,
						cc_last_four: null,
						cc_expiration: null,
						default_account: null,
						tokenizer_url: null,
					},
					CC: {
						id: null,
						payment_name: "New Credit Card",
						integration: "CC",
						profile_id: null,
						acct_id: null,
						token: null,
						accountholder_name: null,
						account_type: null,
						cc_last_four: null,
						cc_expiration: null,
						default_account: null,
						tokenizer_url:
							"https://fts.cardconnect.com/itoke/ajax-tokenizer.html",
					},
				},
			};

			const setIsSubmitDisabled = () => {
				console.log("setIsSubmitDisabled");
			};

			const setAddCardInfo = () => {
				console.log("setAddCardInfo");
			};

			return {
				conditionalElementPlaceholder1: (
					<SelectPaymentMethodsForm
						paymentMethods={step6DummyData.payment_methods}
					/>
				),
				conditionalElementPlaceholder2: (
					<CashPaymentBalanceCalculatorForm
						paymentAmount={300}
						setIsSubmitDisabled={setIsSubmitDisabled}
					/>
				),
				conditionalElementPlaceholder3: (
					<CreditCardAddForm
						loading={loading}
						setLoading={setLoading}
						//@todo : replace tokenizerUrl with actual URL from API
						tokenizerUrl="https://fts.cardconnect.com/itoke/ajax-tokenizer.html"
						setIsSubmitDisabled={setIsSubmitDisabled}
						addCardInfo={creditCardInfo}
						setAddCardInfo={setCreditCardInfo}
					/>
				),
			};
		}

		if (step === 7) {
			const step7DummyData = {
				payment_methods: {
					CC: {
						id: null,
						payment_name: "New Credit Card",
						integration: "CC",
						profile_id: null,
						acct_id: null,
						token: null,
						accountholder_name: null,
						account_type: null,
						cc_last_four: null,
						cc_expiration: null,
						default_account: null,
						tokenizer_url:
							"https://fts.cardconnect.com/itoke/ajax-tokenizer.html",
					},
				},
			};

			const setIsSubmitDisabled = () => {
				console.log("setIsSubmitDisabled");
			};

			const setAddCardInfo = () => {
				console.log("setAddCardInfo");
			};

			return {
				conditionalElementPlaceholder1: (
					<>
						<div className="text-emerald-500 border-2 rounded-md text-sm p-2 text-center border-emerald-200 border-solid bg-emerald-100">
							ABP is neither required nor enabled for this
							contract, but we encourage you to enable it for the
							customer's convenience.
						</div>
						<br />
						<hr />
						<div className="flex justify-center">
							<span className="text-cyan-800">
								Please select Credit / Debit Card for ABP
							</span>
						</div>

						<br />
					</>
				),
				conditionalElementPlaceholder2: (
					<SelectPaymentMethodsForm
						paymentMethods={step7DummyData.payment_methods}
					/>
				),
				conditionalElementPlaceholder3: (
					<>
						<br />
						<div className="text-zinc-800 text-sm rounded-xl p-3 bg-slate-200">
							<div className="col-12">
								<div className="text-center text-red-600">
									<h4>
										Authorization with immediate void for
										ABP card validation
									</h4>
								</div>

								<div className="flex justify-between">
									<div className="px-5 text-left">
										<b>Authorization Amount</b>
									</div>

									<div className="px-5 text-right">
										<b>$1.00</b>
									</div>
								</div>
							</div>
						</div>
					</>
				),
				conditionalElementPlaceholder4: (
					<CreditCardAddForm
						loading={loading}
						setLoading={setLoading}
						tokenizerUrl="https://fts.cardconnect.com/itoke/ajax-tokenizer.html"
						setIsSubmitDisabled={setIsSubmitDisabled}
						addCardInfo={console.log("addCardInfo")}
						setAddCardInfo={setAddCardInfo}
					/>
				),
			};
		}

		return {};
	};

	const extractFormData = (formState, definition) => {
		return Object.fromEntries(
			Object.entries(formState).map(([fieldName, field]) => {
				const myPrefix = definition.fields[fieldName]?.prefix;
				if (myPrefix !== undefined) {
					if (field.value.length > 0) {
						return [fieldName, field.value.replace(myPrefix, "")];
					} else {
						return [fieldName, field.value];
					}
				} else {
					return [fieldName, field.value];
				}
			})
		);
	};

	const handleNextStepResponse = async (response) => {
		setLoading(true);
		// console.log("step", step);
		// console.log("handleNextStepResponse", response);
		if (response && response.data) {
			switch (step) {
				case 4:
					break;
				case 3:
					setCanContinue(response.data.can_continue);
					if (response.data.can_continue === 1) {
						setNextStep("step_5");
						setStep(4);

						setFormState({});

						fetchTmpContractData(tmpContractId, "step_4");
						setStep4TriggerFormUpdate(true);

						setExtraContractCreateData({
							...extraContractCreateData,
							step: "step_4",
						});
					}

					break;
				case 2:
					setCanContinue(response.data.can_continue);
					if (response.data.can_continue === true) {
						setNextStep("step_4");
						setStep(3);

						setDefinitionContractCreateOverride(
							_.cloneDeep(contractsCreateStep3Definition)
						);

						setExtraContractCreateData({
							...extraContractCreateData,
							step: "step_3",
						});

						setLoading(true);
						const response = await ContractsCreateService.index({
							...extraContractCreateData,
							step: "step_3",
						});

						// Set the selector values
						setStep3Selector(response.data.data.selector);

						// Enable continue button if store_require_id_verification is 0
						setStep3StoreRequireIdVerification(
							response.data.data.store_require_id_verification
						);
						if (
							response.data.data.store_require_id_verification ===
							0
						) {
							setCanContinue(true);
							setExtraContractCreateData({
								...extraContractCreateData,
								step: "step_4",
							});
							setSendEmptyContractCreateData(true);
						} else {
							setCanContinue(false);
							setExtraContractCreateData({
								...extraContractCreateData,
								step: "step_3",
							});
							setStep3TriggerFormUpdate(true);
						}
					}

					break;
				case 1:
					setCanContinue(response.data.can_continue);

					if (response.data.can_continue === true) {
						// Reset form state and read-only status
						step2ResetFormState();

						setNextStep("step_3");
						setStep(2);

						const rest2 = { ...extraContractCreateData };
						delete rest2.action;
						setExtraContractCreateData({
							...rest2,
							step: "step_2",
							tmp_contracts_id:
								response.data.data.tmp_contracts_id, // Add tmp_contracts_id to extraContractCreateData
						});
						setDefinitionContractCreateOverride(
							_.cloneDeep(contractsCreateStep2Definition)
						);

						// reset conditional visibility
						setStep2IsVerifyAddressVisible(true);
						setStep2IsEmailVerificationVisible(false);

						// make sure continue button is disabled
						setCanContinue(false);
					} else {
						const rest2 = { ...extraContractCreateData };
						delete rest2.action;
						setExtraContractCreateData({
							...rest2,
							step: "step_2",
							tmp_contracts_id:
								response.data.data.tmp_contracts_id, // Add tmp_contracts_id to extraContractCreateData
						});
						setDefinitionContractCreateOverride(
							_.cloneDeep(contractsCreateStep1Definition)
						);
					}

					break;
				case 0:
					setNextStep("step_2");
					setStep(1);

					setTmpContractId(response.data.data.tmp_contracts_id);
					setFormState((prevState) => ({
						...prevState,
						tmp_contracts_id: {
							value: response.data.data.tmp_contracts_id,
							isValid: true,
						},
						// Reset lock_enabled when moving to the next step
						lock_enabled: {
							value: response.data.data.lock_enabled === 1,
							isValid: true,
						},
					}));

					setCanContinue(response.data.can_continue);

					const rest1 = { ...extraContractCreateData };
					delete rest1.action;
					setExtraContractCreateData({
						...rest1,
						step: "step_1",
						tmp_contracts_id: response.data.data.tmp_contracts_id, // Add tmp_contracts_id to extraContractCreateData
					});

					setStep1EnabledLockRequired(
						response.data.data.lock_required
					);
					setStep1EnabledLockEnabled(response.data.data.lock_enabled);
					setStep1EnabledAbpRequired(response.data.data.abp_required);
					setStep1EnabledAbpEnabled(response.data.data.abp_enabled);

					updateContractCreateDefinitionWithValues(
						[
							response.data.data.lock_required,
							response.data.data.lock_enabled,
							response.data.data.abp_required,
							response.data.data.abp_enabled,
						],
						formState,
						_.cloneDeep(contractsCreateStep1Definition)
					);
					break;
				default:
					setTmpContracts(response.data.data.tmp_contracts || []);
					setDefinitionContractCreateOverride(
						_.cloneDeep(contractsCreateStep0Definition)
					);
					setCanContinue(true);
					break;
			}
		}
		setTimeout(() => {
			setLoading(false);
		}, 200);
	};

	const handleDeleteAction = (tmpContractId) => {
		confirmAlert({
			title: "Are you sure?",
			message:
				"Are you sure you want to delete this temporary contract? This action cannot be reversed.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						deleteTmpContractRecord(tmpContractId);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const deleteTmpContractRecord = async (tmpContractId) => {
		setLoading(true);
		const response = await ContractsCreateService.delete(tmpContractId);
		if (response && response.data) {
			if (response.data.isOk) {
				toast.success(response.data.msg, {
					position: "top-center",
					autoClose: 1000,
				});

				const updatedTmpContracts =
					response.data.data.tmp_contracts || [];
				setTmpContracts(updatedTmpContracts);
				setStep(0);
				setNextStep("step_1");
				setTmpContracts(response.data.data.tmp_contracts || []);
				setDefinitionContractCreateOverride(
					_.cloneDeep(contractsCreateStep0Definition)
				);
				setCanContinue(true);
				// Remove tmp_contracts_id from extraContractCreateData and reset action to "new"
				setExtraContractCreateData((prevData) => {
					const { tmp_contracts_id, ...rest } = prevData;
					return { ...rest, action: "new", step: "step_0" };
				});
			} else {
				toast.error(response.data.msg, {
					position: "top-center",
					autoClose: 1000,
				});
			}
		}
		setLoading(false);
	};

	const updateContractCreateDefinitionWithValues = (
		data,
		formState,
		baseDefinition
	) => {
		console.log(
			"updateContractCreateDefinitionWithValues",
			data,
			formState,
			baseDefinition,
			step
		);
		const updatedDefinition = _.cloneDeep(baseDefinition);

		// step 1 specific logic
		if (step === 1) {
			// Recursively remove existing 'device_identifier' and 'device_model' fields from layout
			updatedDefinition.layout = updatedDefinition.layout.map(
				(layoutItem) => {
					if (layoutItem.items) {
						layoutItem.items = removeFields(layoutItem.items, [
							"device_identifier",
							"device_model",
						]);
					}
					return layoutItem;
				}
			);

			const lockRequiredComputed = data[0] === 1;
			const lockEnabledComputed = data[1] === 1;
			const abpRequiredComputed = data[2] === 1;
			const abpEnabledComputed = data[3] === 1;

			// Ensure the lock_enabled value is preserved in the new definition
			if (updatedDefinition.fields && formState.lock_enabled) {
				updatedDefinition.fields.lock_enabled = {
					...updatedDefinition.fields.lock_enabled,
					value: lockEnabledComputed, // Set value based on lockEnabled
					readOnly: lockRequiredComputed, // Set readOnly based on lockRequired
				};
				updatedDefinition.fields.abp_enabled = {
					...updatedDefinition.fields.abp_enabled,
					value: abpEnabledComputed, // Set value based on abpEnabled
					readOnly: abpRequiredComputed, // Set readOnly based on abpRequired
				};
			}

			// Ensure the tmp_contracts_id value is set in the new definition
			if (updatedDefinition.fields && formState.tmp_contracts_id) {
				updatedDefinition.fields.tmp_contracts_id = {
					...updatedDefinition.fields.tmp_contracts_id,
					value: formState.tmp_contracts_id.value,
				};
			}

			if (formState.lock_enabled?.value === true) {
				// Example of adding fields back, adjust according to where they need to be added
				const newFields = [
					{
						type: "div",
						class: "",
						items: [
							{
								type: "div",
								class: "row mb-3",
								items: [
									{
										type: "input",
										name: "device_identifier",
										class: "col-md-12",
										label: "IMEI / Serial",
										value: "",
										required: true,
										readOnly: false,
										upperCase: true,
										onBlur: step1HandleImeiBlur,
									},
								],
							},
						],
					},
					{
						type: "div",
						class: "",
						items: [
							{
								type: "div",
								class: "row mb-3",
								items: [
									{
										type: "input",
										name: "device_model",
										class: "col-md-12",
										label: "Device Manufacturer / Model",
										value: "N/A",
										required: true,
										readOnly: true,
										upperCase: true,
									},
								],
							},
						],
					},
				];

				const result = findIndexAndParentRecursive(
					updatedDefinition.layout[0].items,
					"lock_enabled"
				);

				if (result !== null) {
					result.parent.splice(result.index + 1, 0, ...newFields);
				} else {
					// Fallback if lock_enabled is not found
					updatedDefinition.layout[0].items.push(...newFields);
				}

				// Update fields object
				updatedDefinition.fields = {
					...updatedDefinition.fields,
					device_identifier: {
						type: "input",
						label: "IMEI / Serial",
						value: "",
						required: true,
						readOnly: false,
						upperCase: true,
					},
					device_model: {
						type: "input",
						label: "Device Manufacturer / Model",
						value: "N/A",
						required: true,
						readOnly: true,
						upperCase: true,
					},
				};
			}
		}

		// step 2 specific logic
		if (step === 2) {
			const addNewsletterField = data.includes("addNewsletterField");

			if (addNewsletterField) {
				const newsletterField = {
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "checkbox",
									name: "newsletter_enabled",
									class: "col-md-12",
								},
							],
						},
					],
				};

				const result = findIndexAndParentRecursive(
					updatedDefinition.layout[0].items,
					"conditionalElementPlaceholder2"
				);

				if (result !== null) {
					result.parent.splice(result.index + 1, 0, newsletterField);
				} else {
					// Fallback if conditionalElementPlaceholder2 is not found
					updatedDefinition.layout[0].items.push(newsletterField);
				}

				// Update fields object
				updatedDefinition.fields = {
					...updatedDefinition.fields,
					newsletter_enabled: {
						type: "checkbox",
						label: `Yes, sign me up! By checking this box, I agree and I want to receive news, offers, payment reminders, and account alerts from RTO Mobile & ${SessionService.getUserStoreName()}, including by email, phone, sms, and mail to the contact information I am submitting. I consent to RTO Mobile & ${SessionService.getUserStoreName()}, its affiliates and service provides process my personal data for these purposes as described in the Privacy Policy. I understand that I can withdraw my consent at any time.`,
						value: false,
						required: true,
						readOnly: false,
						upperCase: false,
					},
				};
			}

			// Preserve existing form field values
			Object.keys(formState).forEach((key) => {
				if (updatedDefinition.fields[key]) {
					updatedDefinition.fields[key].value = formState[key].value;
				}
			});
		}

		// step 3 specific logic
		if (step === 3) {
			const storeRequireIdVerification = data.includes(
				"storeRequireIdVerification"
			);

			if (storeRequireIdVerification) {
				// set id_type and id_number fields to required
				if (updatedDefinition.fields.id_type) {
					updatedDefinition.fields.id_type.required = true;
				}
				if (updatedDefinition.fields.id_number) {
					updatedDefinition.fields.id_number.required = true;
				}
			}

			// Add state field if id_type is 1 or 2
			// Extract idType from data array
			const idTypeValue = data.find(
				(item) => item.idType !== undefined
			)?.idType;
			if (idTypeValue === "1" || idTypeValue === "2") {
				// Update layout object
				updatedDefinition.layout = [
					{
						type: "div",
						class: "row",
						items: [
							{
								type: "div",
								class: "col-10 offset-lg-1",
								items: [
									{
										type: "div",
										class: "row mb-3",
										items: [
											{
												type: "typography",
												name: "step_title",
												class: "col-md-12",
											},
										],
									},
									{
										type: "div",
										class: "row mb-3",
										items: [
											{
												type: "select",
												name: "id_type",
												class: "col-md-6",
											},
											{
												type: "select",
												name: "state",
												class: "col-md-3",
											},
											{
												type: "input",
												name: "id_number",
												class: "col-md-3",
											},
										],
									},
									{
										type: "div",
										class: "row mb-3",
										items: [
											{
												type: "typography",
												text: "How would you like to receive a link to upload images of the customers ID?",
												variant: "p",
												className:
													"text-center py-3 font-light font-sans",
												class: "col-md-12",
											},
										],
									},
									{
										type: "div",
										name: "conditionalElementPlaceholder1",
									},
									{
										type: "div",
										name: "conditionalElementPlaceholder2",
									},
								],
							},
						],
					},
				];

				// Update fields object
				updatedDefinition.fields = {
					...updatedDefinition.fields,
					state: {
						type: "select",
						label: "State",
						options: stateOptions,
						required: true,
						uppercase: true,
						style: {
							textTransform: "uppercase",
						},
					},
				};
			} else if (idTypeValue === "" && !storeRequireIdVerification) {
				// Reset fields if id_type is unselected and store_require_id_verification is 0
				if (updatedDefinition.fields.id_type) {
					updatedDefinition.fields.id_type.value = "";
					updatedDefinition.fields.id_type.required = false;
				}
				if (updatedDefinition.fields.state) {
					updatedDefinition.fields.state.value = "";
					updatedDefinition.fields.state.required = false;
				}
				if (updatedDefinition.fields.id_number) {
					updatedDefinition.fields.id_number.value = "";
					updatedDefinition.fields.id_number.required = false;
				}
			}

			// Preserve existing form field values
			Object.keys(formState).forEach((key) => {
				if (updatedDefinition.fields[key]) {
					// Reset id_number value if id_type is unselected and store_require_id_verification is 0
					if (
						key === "id_number" &&
						idTypeValue === "" &&
						!storeRequireIdVerification
					) {
						updatedDefinition.fields[key].value = "";
					} else {
						updatedDefinition.fields[key].value =
							formState[key].value;
					}
				}
			});

			// Set fields to required if id_type is defined
			if (idTypeValue !== undefined && idTypeValue !== "") {
				if (updatedDefinition.fields.id_type) {
					updatedDefinition.fields.id_type.required = true;
				}
				if (updatedDefinition.fields.state) {
					updatedDefinition.fields.state.required = true;
				}
				if (updatedDefinition.fields.id_number) {
					updatedDefinition.fields.id_number.required = true;
				}
			}
		}

		// step 4 specific logic
		if (step === 4) {
			// Update fields object

			let retailValueCaption = "";
			if (data[0]?.retail_value_caption) {
				retailValueCaption = data[0]?.retail_value_caption?.text;
			} else {
				retailValueCaption = `Retail max value: $${tmpContractCreateData?.store_defaults?.max_retail_value}`;
			}

			updatedDefinition.fields = {
				...updatedDefinition.fields,
				payoff_early_policy_id: {
					type: "select",
					label: "Early Payoff Policy",
					value: "",
					required: true,
					readOnly: false,
					upperCase: true,
					style: {
						textTransform: "uppercase",
					},
					options:
						tmpContractCreateData?.selects?.payoff_early_policy_id,
				},
				merchandise_condition: {
					type: "select",
					label: "Select Merchandise Condition",
					value: "",
					required: true,
					readOnly: false,
					upperCase: true,
					style: {
						textTransform: "uppercase",
					},
					options:
						tmpContractCreateData?.selects?.device_condition_id,
				},
				retail_value: {
					type: "number",
					label: "Retail Value",
					value: tmpContractCreateData?.fields?.retail_value,
					prefix: "$",
					required: true,
					readOnly: false,
					decimalLimit: 2,
					integerOnly: false,
					onBlur: step4HandleRetailValueBlur,
					//@todo: Add onblur event
				},
				retail_value_caption: {
					type: "typography",
					text: retailValueCaption,
					variant: "caption",
					className:
						"text-center py-2 text-cyan-800 font-light font-sans",
				},
				down_payment_amount: {
					type: "number",
					label: "Down Payment",
					value: tmpContractCreateData?.fields?.down_payment_amount,
					prefix: "$",
					required: true,
					readOnly: false,
					decimalLimit: 2,
					integerOnly: false,
				},
				down_payment_amount_caption: {
					type: "typography",
					text: `$0.00 min ${tmpContractCreateData?.store_defaults?.down_payment_min}% |
						$0.00 max ${tmpContractCreateData?.store_defaults?.down_payment_default}% |
						$0.00 default ${tmpContractCreateData?.store_defaults?.down_payment_max}%`,
					variant: "caption",
					className:
						"text-center py-2 text-cyan-800 font-light font-sans",
				},
				rental_factor: {
					type: "number",
					label: "Rental Factor",
					value: tmpContractCreateData?.fields?.rental_factor,
					required: true,
					readOnly: false,
					decimalLimit: 2,
					integerOnly: false,
				},
				rental_factor_caption: {
					type: "typography",
					text: `min ${tmpContractCreateData?.store_defaults?.finance_factor_min} |
						max ${tmpContractCreateData?.store_defaults?.finance_factor_max} |
						default ${tmpContractCreateData?.store_defaults?.finance_factor_default}`,
					variant: "caption",
					className:
						"text-center py-2 text-cyan-800 font-light font-sans",
				},
				total_contract_value: {
					type: "number",
					label: "Total Contract Value",
					value: tmpContractCreateData?.fields?.total_contract_value,
					prefix: "$",
					required: false,
					readOnly: true,
					decimalLimit: 2,
					integerOnly: false,
				},
			};
			// Preserve existing form field values
			Object.keys(formState).forEach((key) => {
				if (updatedDefinition.fields[key]) {
					updatedDefinition.fields[key].value = formState[key].value;
				}
			});
		}

		// Update the state with the new definition
		setDefinitionContractCreateOverride(updatedDefinition);
	};

	// step 0 logic
	useEffect(() => {
		fetchTmpContracts();
	}, []);

	const fetchTmpContracts = async () => {
		setLoading(true);
		const response = await ContractsCreateService.index({
			// initial call without any params
		});
		if (response && response.data) {
			setNextStep("step_1");
			setTmpContracts(response.data.data.tmp_contracts || []);
			setExtraContractCreateData({
				...extraContractCreateData,
				step: response.data.step,
			});
		}
		setLoading(false);
	};

	const removeFields = (items, fieldNames) => {
		return items
			.map((item) => {
				// Recursively filter nested items if they exist
				if (item.items) {
					item.items = removeFields(item.items, fieldNames);
				}
				return item;
			})
			.filter((item) => {
				// Keep the item if it's not one of the specified fields to remove
				if (!fieldNames.includes(item.name)) {
					// Also keep it if it's a container that still has items
					if (item.type === "div") {
						return item.items && item.items.length > 0;
					}
					return true;
				}
				// Otherwise, filter it out
				return false;
			});
	};

	const findIndexAndParentRecursive = (
		items,
		fieldName,
		parentContainer = null,
		grandParentContainer = null
	) => {
		for (let i = 0; i < items.length; i++) {
			if (items[i].name === fieldName) {
				// Return the grandparent container's items array, if available, otherwise fallback to parent or current items
				return {
					parent: grandParentContainer || parentContainer || items,
					index: i,
				};
			}
			if (items[i].items) {
				// Update the lineage of containers: current item's items become the new parent, current parent becomes the new grandparent
				const result = findIndexAndParentRecursive(
					items[i].items,
					fieldName,
					items[i].items,
					parentContainer
				);
				if (result !== null) return result;
			}
		}
		return null;
	};

	// step 1 logic
	const step1HandleImeiBlur = async () => {
		// Extract form data from formState
		const formDataObject = extractFormData(
			formStateRef.current,
			definitionContractCreateOverride
		);

		// Construct the payload
		const payload = {
			step: "step_1",
			tmp_contracts_id: tmpContractId,
			data: formDataObject, // Include all form fields within the data object
		};

		// Send the payload using ContractsCreateService.view
		setLoading(true);
		const response = await ContractsCreateService.index(payload);
		setLoading(false);

		setCanContinue(response.data.can_continue === "1");
	};

	useEffect(() => {
		if (step === 1) {
			updateContractCreateDefinitionWithValues(
				[
					step1EnabledLockRequired,
					step1EnabledLockEnabled,
					step1EnabledAbpRequired,
					step1EnabledAbpEnabled,
				],
				formState,
				_.cloneDeep(contractsCreateStep1Definition)
			);
		}
	}, [step1EnabledLockEnabled, step]);

	const prevEnabledLockValue = useRef(formState?.lock_enabled?.value);
	const prevEnabledAbpValue = useRef(formState?.abp_enabled?.value);

	useEffect(() => {
		if (step === 1 && formState?.lock_enabled?.value !== undefined) {
			if (formState.lock_enabled.value !== prevEnabledLockValue.current) {
				setStep1EnabledLockEnabled(
					formState.lock_enabled.value ? 1 : 0
				);
				prevEnabledLockValue.current = formState.lock_enabled.value;
			}
		}
	}, [formState?.lock_enabled?.value, step]);

	useEffect(() => {
		if (step === 1 && formState?.abp_enabled?.value !== undefined) {
			if (formState.abp_enabled.value !== prevEnabledAbpValue.current) {
				setStep1EnabledAbpEnabled(formState.abp_enabled.value ? 1 : 0);
				prevEnabledAbpValue.current = formState.abp_enabled.value;
			}
		}
	}, [formState?.abp_enabled?.value, step]);

	// step 2 logic
	const step2UpdateFieldsToReadOnly = (
		fields,
		formStateRef,
		setDefinitionContractCreateOverride,
		setFormState
	) => {
		setDefinitionContractCreateOverride((prevDefinition) => {
			const newDefinition = { ...prevDefinition };
			fields.forEach((fieldName) => {
				if (newDefinition.fields[fieldName]) {
					newDefinition.fields[fieldName] = {
						...newDefinition.fields[fieldName],
						readOnly: true,
						value:
							formStateRef.current[fieldName]?.value ||
							newDefinition.fields[fieldName].value,
					};
				}
			});
			return newDefinition;
		});

		// Update formState to reflect the changes
		setFormState((prevFormState) => {
			const updatedFormState = { ...prevFormState };
			fields.forEach((fieldName) => {
				if (updatedFormState[fieldName]) {
					updatedFormState[fieldName] = {
						...updatedFormState[fieldName],
						readOnly: true,
					};
				}
			});
			return updatedFormState;
		});
	};

	const step2HandleAddressSubmit = async () => {
		if (formRef.current) {
			setLoading(true);
			const isValid = await formRef.current.validateAllFields(true); // Call the validation function
			if (isValid) {
				// Extract form data from formState
				const formDataObject = extractFormData(
					formStateRef.current,
					definitionContractCreateOverride
				);

				const response = await ContractsCreateService.verifyPersonnel(
					formDataObject,
					tmpContractId
				);

				if (response.data.isOk) {
					// Extract form data from formState
					const formDataObject = extractFormData(
						formStateRef.current,
						definitionContractCreateOverride
					);

					const response = await ContractsCreateService.verifyAddress(
						formDataObject,
						tmpContractId
					);

					// if (response.data.isOk) {
					if (!response.data.errors) {
						if (!response.data.data.address_in_match_usps) {
							// Update the state with the address data
							setStep2EnteredAddress(
								response.data.data.address_in
							);
							setStep2FoundAddress(
								response.data.data.address_usps
							);

							// display modal
							setStep2IsModalOpen(true); // Display modal

							return false;
						}

						setStep2IsVerifyAddressVisible(false);
						setStep2IsEmailVerificationVisible(true);

						step2UpdateFieldsToReadOnly(
							step2ReadOnlyFields,
							formStateRef,
							setDefinitionContractCreateOverride,
							setFormState
						);
					} else {
						// Show errors
						if (response.data.errors) {
							//for each error, show a toast
							response.data.errors.forEach((error, index) => {
								setTimeout(() => {
									toast.error(`Error: ${error.msg}`, {
										position: "top-center",
										autoClose: 5000,
									});
								}, index * 1000);
							});
						}
					}
				} else {
					// Show errors
					if (response.data.errors) {
						//for each error, show a toast
						response.data.errors.forEach((error, index) => {
							setTimeout(() => {
								toast.error(`Error: ${error.msg}`, {
									position: "top-center",
									autoClose: 5000,
								});
							}, index * 1000);
						});
					}
				}
			}
			setTimeout(() => {
				setLoading(false);
			}, 200);
		}
	};

	const step2HandleEmailSubmit = async (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!email) {
			toast.error(`Error: Email address is required.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}
		if (!emailRegex.test(email)) {
			toast.error(`Error: Email address is invalid.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}

		setLoading(true);
		const response = await ContractsCreateService.verifyEmail(
			email,
			tmpContractId
		);

		if (response.data.isOk) {
			// need to set isEmailSent to true
			setStep2IsEmailSent(true);

			// if user is system admin set code value
			if (SessionService.canSystemAdmin()) {
				setStep2EmailCode(response.data.data.email.email_address_code);
			}
		} else {
			// Show errors
			if (response.data.errors) {
				//for each error, show a toast
				response.data.errors.forEach((error, index) => {
					setTimeout(() => {
						toast.error(`Error: ${error.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
					}, index * 1000);
				});
			}
		}

		setLoading(false);
	};

	const step2HandleCodeSubmit = async (email, code) => {
		if (!code) {
			toast.error(`Error: Verification code is required.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}

		setLoading(true);
		const response = await ContractsCreateService.verifyEmailCode(
			email,
			code,
			tmpContractId
		);

		if (response.data.can_continue) {
			// Add the newsletter_enabled checkbox to the form
			updateContractCreateDefinitionWithValues(
				["addNewsletterField"],
				formState,
				_.cloneDeep(contractsCreateStep2Definition)
			);
			setCanContinue(response.data.can_continue);

			// Disable the email verification field
			setStep2IsEmailVerificationDisabled(true);

			step2UpdateFieldsToReadOnly(
				step2ReadOnlyFields,
				formStateRef,
				setDefinitionContractCreateOverride,
				setFormState
			);
		} else {
			// Show errors
			if (response.data.errors) {
				//for each error, show a toast
				response.data.errors.forEach((error, index) => {
					setTimeout(() => {
						toast.error(`Error: ${error.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
					}, index * 1000);
				});
			}
		}

		setLoading(false);
	};

	const step2HandleModalClose = (disableLoading = true) => {
		setStep2IsModalOpen(false);
		if (disableLoading) {
			setLoading(false);
		}
	};

	const step2HandleContinueAddress = () => {
		setStep2IsVerifyAddressVisible(false);
		setStep2IsEmailVerificationVisible(true);

		step2UpdateFieldsToReadOnly(
			step2ReadOnlyFields,
			formStateRef,
			setDefinitionContractCreateOverride,
			setFormState
		);
		step2HandleModalClose();
	};

	const step2HandleUpdateAddress = () => {
		const updatedFormState = {
			...formState,
			address_1: {
				...formState.address_1,
				value: step2FoundAddress.address_1,
			},
			address_2: {
				...formState.address_2,
				value: step2FoundAddress.address_2,
			},
			city: { ...formState.city, value: step2FoundAddress.city },
			state: { ...formState.state, value: step2FoundAddress.state },
			zip: { ...formState.zip, value: step2FoundAddress.zip },
		};

		setFormState(updatedFormState);

		updateContractCreateDefinitionWithValues(
			[],
			updatedFormState,
			_.cloneDeep(contractsCreateStep2Definition)
		);

		step2HandleAddressSubmit();
		step2HandleModalClose(false);
	};

	const step2ResetFormState = () => {
		setDefinitionContractCreateOverride(
			_.cloneDeep(contractsCreateStep2Definition)
		);
		setFormState({});
		setStep2IsVerifyAddressVisible(true);
		setStep2IsEmailVerificationVisible(false);
		setStep2IsEmailSent(false);
		setStep2IsEmailVerificationDisabled(false);
	};

	const step2ModalBody = (
		<div className="mt-3 row">
			<div className="col-md-6">
				<div className="mb-3 border-2 border-sky-200 border-solid rounded-md">
					<div className="text-sky-500 bg-sky-100 border-b-2 rounded-tl rounded-tr py-3 px-4 border-sky-200 border-solid">
						<h6 className="text-center font-normal mb-0">
							Entered Address
						</h6>
					</div>

					<div className="p-4">
						Address: {step2EnteredAddress.address_1}
						<br />
						Suite / Apt: {step2EnteredAddress.address_2}
						<br />
						City: {step2EnteredAddress.city}
						<br />
						State: {step2EnteredAddress.state}
						<br />
						Zip: {step2EnteredAddress.zip}
						<br />
					</div>
				</div>
			</div>

			<div className="col-md-6">
				<div className="mb-3 border-2 border-emerald-200 border-solid rounded-md">
					<div className="text-emerald-500 bg-emerald-100 border-b-2 rounded-tl rounded-tr py-3 px-4 border-emerald-200 border-solid">
						<h6 className="text-center font-normal mb-0">
							Found Address
						</h6>
					</div>

					<div className="p-4">
						Address: {step2FoundAddress.address_1}
						<br />
						Suite / Apt: {step2FoundAddress.address_2}
						<br />
						City: {step2FoundAddress.city}
						<br />
						State: {step2FoundAddress.state}
						<br />
						Zip: {step2FoundAddress.zip}
						<br />
					</div>
				</div>
			</div>
		</div>
	);

	// step 3 logic
	useEffect(() => {
		if (step3TriggerFormUpdate) {
			updateContractCreateDefinitionWithValues(
				[
					step3StoreRequireIdVerification
						? "storeRequireIdVerification"
						: "",
				],
				formState,
				_.cloneDeep(contractsCreateStep3Definition)
			);
			setStep3TriggerFormUpdate(false); // Reset the trigger
		}
	}, [step3TriggerFormUpdate]);

	useEffect(() => {
		if (step === 3 && step3IdType !== undefined) {
			updateContractCreateDefinitionWithValues(
				[
					step3StoreRequireIdVerification
						? "storeRequireIdVerification"
						: "",
					{ idType: step3IdType },
				],
				formState,
				_.cloneDeep(contractsCreateStep3Definition)
			);
		}
	}, [step3IdType, step]);

	const prevIdTypeValue = useRef(formState?.id_type?.value);

	useEffect(() => {
		if (step === 3 && formState?.id_type?.value !== undefined) {
			if (formState.id_type.value !== prevIdTypeValue.current) {
				setStep3IdType(formState.id_type.value);
				prevIdTypeValue.current = formState.id_type.value;
			}
		}
	}, [formState?.id_type?.value, step]);

	const step3HandleContactSubmit = async (contact, verificationType) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isEmail = verificationType.includes("email");
		const contactType = isEmail ? "email" : "phone";

		if (!contact) {
			toast.error(`Error: Custom ${contactType} is required.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}

		if (isEmail && !emailRegex.test(contact)) {
			toast.error(`Error: Custom email is invalid.`, {
				position: "top-center",
				autoClose: 1000,
			});
			return;
		}

		setLoading(true);
		const response = await ContractsCreateService.sendIdLink(
			verificationType,
			contact,
			tmpContractId
		);

		if (response.data.isOk) {
			// need to set isLinkSent to true
			setStep3IsLinkSent(true);

			const response = await ContractsCreateService.index({
				step: "step_3",
				tmp_contracts_id: tmpContractId,
			});

			// set image refresh interval
			if (response.data.refresh_interval) {
				setStep3ImageRefreshInterval(response.data.refresh_interval);
			}

			// set image count
			if (response.data.data.image_count) {
				setStep3ImageCount(response.data.data.image_count);
			}
		} else {
			// Show errors
			if (response.data.errors) {
				//for each error, show a toast
				response.data.errors.forEach((error, index) => {
					setTimeout(() => {
						toast.error(`Error: ${error.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
					}, index * 1000);
				});
			}
		}

		setLoading(false);
	};

	useEffect(() => {
		let intervalId;

		const fetchImages = async () => {
			// console.log("Fetching images...");
			try {
				const response = await ContractsCreateService.index({
					step: "step_3",
					tmp_contracts_id: tmpContractId,
				});

				if (response.data.data.images) {
					const images = response.data.data.images;
					setStep3Image1(images.image_1 || null);
					setStep3Image2(images.image_2 || null);
					setStep3Image3(images.image_3 || null);
				}

				// Check image count and update canContinue
				if (response.data.data.image_count !== undefined) {
					const imageCount = response.data.data.image_count;
					setStep3ImageCount(imageCount);

					// Update canContinue based on image count and store requirement
					if (imageCount >= 1 && imageCount < 3) {
						setCanContinue(false);
					} else if (imageCount === 0) {
						setCanContinue(!step3StoreRequireIdVerification);
					} else if (imageCount >= 3) {
						setCanContinue(true);
					}

					// Additional check for when store doesn't require verification but images exist and id_type is not set
					if (
						!step3StoreRequireIdVerification &&
						imageCount > 0 &&
						!formState.id_type?.value
					) {
						setCanContinue(false);
					}

					if (imageCount >= 3) {
						// console.log("All images received. Stopping refresh.");
						clearInterval(intervalId);
					}
				}
			} catch (error) {
				console.error("Error fetching images:", error);
			}
		};

		if (step3ImageRefreshInterval && step === 3) {
			// Initial fetch
			fetchImages();

			// Set up interval
			intervalId = setInterval(fetchImages, step3ImageRefreshInterval);
		}

		// Cleanup function
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [
		step3ImageRefreshInterval,
		step,
		tmpContractId,
		step3StoreRequireIdVerification,
		formState.id_type?.value,
	]);

	const step3RecaptureImage = async (imageNumber) => {
		setLoading(true);
		const response = await ContractsCreateService.recaptureImage(
			imageNumber,
			tmpContractId
		);

		if (response.data.isOk) {
			const response = await ContractsCreateService.index({
				step: "step_3",
				tmp_contracts_id: tmpContractId,
			});

			// set image refresh interval
			if (response.data.refresh_interval) {
				setStep3ImageRefreshInterval(response.data.refresh_interval);
			}

			// set image count
			if (response.data.data.image_count) {
				// setStep3ImageCount(response.data.data.image_count);
			}
		} else {
			// Show errors
			if (response.data.errors) {
				//for each error, show a toast
				response.data.errors.forEach((error, index) => {
					setTimeout(() => {
						toast.error(`Error: ${error.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
					}, index * 1000);
				});
			}
		}

		setLoading(false);
	};

	// step 4 logic
	useEffect(() => {
		if (step4TriggerFormUpdate) {
			updateContractCreateDefinitionWithValues(
				[],
				formState,
				_.cloneDeep(contractsCreateStep4Definition)
			);
			setStep4TriggerFormUpdate(false); // Reset the trigger
		}
	}, [step4TriggerFormUpdate]);

	const step4HandleRetailValueBlur = async () => {
		// Extract form data from formState
		const formDataObject = extractFormData(
			formStateRef.current,
			definitionContractCreateOverride
		);

		// Construct the payload
		const payload = {
			step: "step_4",
			tmp_contracts_id: tmpContractId,
			//tmp_contracts_id: 18345,
			data: formDataObject, // Include all form fields within the data object
		};
		setLoading(true);
		const response = await ContractsCreateService.index(payload);
		if (response && response.data) {
			setTmpContractCreateData(response.data.data || []);
			const updatedFormState = {
				...formState,
				retail_value: {
					...formState.retail_value,
					value: String(
						parseNumeric(response?.data?.data?.fields?.retail_value)
					),
				},
				down_payment_amount: {
					...formState.down_payment_amount,
					value: String(
						parseNumeric(
							response?.data?.data?.fields?.down_payment_amount
						)
					),
				},
				rental_factor: {
					...formState.rental_factor,
					value: String(
						parseNumeric(
							response?.data?.data?.fields?.rental_factor
						)
					),
				},
				total_contract_value: {
					...formState.total_contract_value,
					value: String(
						parseNumeric(
							response?.data?.data?.fields?.total_contract_value
						)
					),
				},
				retail_value_caption: {
					...formState.retail_value_caption,
					value: `Retail max value@: $${response?.data?.data?.store_defaults?.max_retail_value}`,
				},
				down_payment_amount_caption: {
					...formState.down_payment_amount_caption,
					value: `$0.00 min@ ${response?.data?.data?.store_defaults?.down_payment_min}% |
						$0.00 max ${response?.data?.data?.store_defaults?.down_payment_default}% |
						$0.00 default ${response?.data?.data?.store_defaults?.down_payment_max}%`,
				},
				rental_factor_caption: {
					...formState.rental_factor_caption,
					value: `min@ ${response?.data?.data?.store_defaults?.finance_factor_min} |
						max ${response?.data?.data?.store_defaults?.finance_factor_max} |
						default ${response?.data?.data?.store_defaults?.finance_factor_default}`,
				},
			};

			updateContractCreateDefinitionWithValues(
				[],
				updatedFormState,
				_.cloneDeep(contractsCreateStep4Definition)
			);
		}
		setLoading(false);
	};

	const step4PaymentTermOnChange = (event, month, period) => {
		// console.log("step4PaymentTermOnChange -> " + month + " " + period);
	};

	const step4PlanDayOnChange = (fieldName, value, pIsValid) => {
		// console.log("step4PlanDayOnChange value -> ", value);
	};

	const step4PlanDateOnChange = () => {
		// console.log("step4PlanDateOnChange value", value);
	};

	return (
		<div className="sm:p-3 text-zinc-800 text-sm bg-slate-100 rounded">
			<br />
			{step !== 0 && (
				<div className="mx-[150px]">
					<MultiStepFormStepper
						steps={steps}
						step={step}
						onStepClick={handleStepClick}
					/>
				</div>
			)}
			<TopModal
				title="Confirm Address"
				body={step2ModalBody}
				footer={
					<>
						<Button
							onClick={step2HandleContinueAddress}
							variant="contained"
							color="primary"
							disableElevation
						>
							<span className="font-normal">Continue</span>
						</Button>
						<Button
							onClick={step2HandleUpdateAddress}
							variant="contained"
							color="success"
							disableElevation
						>
							<span className="font-normal">Update Address</span>
						</Button>
					</>
				}
				isVisible={step2IsModalOpen}
				onClose={step2HandleModalClose}
				loading={loading}
			/>
			<DynamicForm
				loading={loading}
				setLoading={setLoading}
				model="contracts_create"
				modelLabel="Create Contract"
				service={ContractsCreateService}
				apiBase="/contracts/contract-create"
				// successRedirectBase="settings/rto-company-payment-methods"
				scenario="create"
				// key={modelId + "contracts_create"}
				key={createResetCounter + "contracts_create"}
				display="partial"
				createEndpointOverride="/contracts/contract-create"
				updateEndpointOverride="/contracts/contract-create"
				definitionOverride={definitionContractCreateOverride}
				extraData={extraContractCreateData}
				sendEmptyData={sendEmptyContractCreateData}
				// scenario={isUpdateMode ? "update" : "create"}
				// updateId={updateId}
				fetchData={handleNextStepResponse}
				suppressSuccessToast={true}
				onFormChange={handleFormChange}
				renderConditionalElement={renderConditionalElement}
				actionHandlers={actionHandlers}
				canContinue={canContinue}
				ref={formRef}
			/>
		</div>
	);
}

export default ContractsCreateForm;
