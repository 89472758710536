import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getPaymentImage } from "../../helpers/Utils";

/**
 * @param {*} paymentMethods array of payment methods
 * Example:
 * {
    "CASH": {
        "id": null,
        "payment_name": "Cash Payment",
        "integration": "CASH",
        "profile_id": null,
        "acct_id": null,
        "token": null,
        "accountholder_name": null,
        "account_type": null,
        "cc_last_four": null,
        "cc_expiration": null,
        "default_account": null,
        "tokenizer_url": null
    },
    "CC": {
        "id": null,
        "payment_name": "New Credit Card",
        "integration": "CC",
        "profile_id": null,
        "acct_id": null,
        "token": null,
        "accountholder_name": null,
        "account_type": null,
        "cc_last_four": null,
        "cc_expiration": null,
        "default_account": null,
        "tokenizer_url": "https://fts.cardconnect.com/itoke/ajax-tokenizer.html"
    }}
 * @param {*} selectedPayment selected payment method use for parent component
 * @param {*} setSelectedPayment set selected payment method use for parent component
 * @param {*} setIsSubmitDisabled set is submit button disable use for parent component
 * @returns
 */
const SelectPaymentMethodsForm = ({
	paymentMethods = {},
	selectedPayment = null,
	setSelectedPayment = null,
	setIsSubmitDisabled = null,
}) => {
	const [paymentInfo, setPaymentInfo] = useState({});

	/*useEffect(() => {
		//contractPaymentGet();
	}, [paymentMethods]);
	*/
	//console.log(", paymentMethods);
	return (
		<FormControl
			// variant="standard"
			sx={{
				width: "100%",
				color: "#21557a",
			}}
		>
			<InputLabel id="payment-method-label">Payment Method</InputLabel>
			<Select
				labelId="payment-method-label"
				id="payment-method-select"
				value={selectedPayment}
				MenuProps={{
					PaperProps: { style: { width: "50%" } },
				}}
				onChange={(event) => {
					setSelectedPayment(event?.target?.value);
					if (event?.target?.value != "CASH") {
						setIsSubmitDisabled(false);
					}
				}}
				autoWidth
				label="Payment Method"
				placeholder="Select Payment Method"
			>
				{paymentMethods
					? Object?.keys(paymentMethods)?.map(
							(key) =>
								key !== "" && (
									<MenuItem key={key} value={key}>
										{paymentMethods[key]?.id &&
											getPaymentImage(
												paymentMethods[key]
													?.account_type
											)}
										{paymentMethods[key]?.payment_name}
									</MenuItem>
								)
					  )
					: null}
			</Select>
		</FormControl>
	);
};
export default SelectPaymentMethodsForm;
