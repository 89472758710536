import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import {
	FormControlLabel,
	Radio,
	RadioGroup,
	Switch,
	FormControl,
	FormHelperText,
} from "@mui/material";

const CustomRadioComponent = forwardRef(
	(
		{
			name,
			value,
			onChange,
			options,
			className,
			required,
			displayAsSwitches = false,
			...otherProps
		},
		ref
	) => {
		const [internalValue, setInternalValue] = useState(value);
		const [isValid, setIsValid] = useState(true);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(value);
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				validate();
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useImperativeHandle(ref, () => ({
			validate: () => validate(),
			isTouched: () => isTouched,
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const handleChange = (event) => {
			const newValue = event.target.value;
			setInternalValue(newValue);
			validate(newValue);
			onChange(name, newValue, isValid);
		};

		const validate = (valueToValidate = internalValue) => {
			if (name === "create_or_resume" && valueToValidate === "resume") {
				setIsValid(false);
				setErrorMessage("");
				onChange(name, valueToValidate, false);
				return false;
			}

			if (required && !valueToValidate) {
				setIsValid(false);
				setErrorMessage("This field is required.");
				setIsTouched(true);
				onChange(name, valueToValidate, false);
				return false;
			} else {
				setIsValid(true);
				setErrorMessage("");
				onChange(name, valueToValidate, true);
				return true;
			}
		};

		const handleFocus = () => {
			setIsTouched(true);
			validate();
		};

		const labelStyle = {
			"& .MuiFormControlLabel-asterisk": {
				display: "none",
			},
		};

		return (
			<FormControl error={!isValid && isTouched} className={className}>
				<RadioGroup
					name={name}
					value={internalValue}
					onChange={handleChange}
					onFocus={handleFocus}
					{...otherProps}
				>
					{options.map((option, index) => (
						<FormControlLabel
							key={index}
							value={option.value}
							control={
								displayAsSwitches ? (
									<Switch
										checked={internalValue === option.value}
										onChange={handleChange}
										required={required}
									/>
								) : (
									<Radio required={required} />
								)
							}
							label={option.label}
							sx={required ? labelStyle : {}}
							inputRef={inputRef}
						/>
					))}
				</RadioGroup>
				{!isValid && isTouched && (
					<FormHelperText>{errorMessage}</FormHelperText>
				)}
			</FormControl>
		);
	}
);

export default CustomRadioComponent;
