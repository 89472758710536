import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";

function RtoEmailVerificationField({
	handleEmailSubmit,
	isEmailSent,
	setIsEmailSent,
	handleCodeSubmit,
	disabled,
	defaultCode,
}) {
	const [email, setEmail] = useState("");
	const [code, setCode] = useState(defaultCode || "");

	useEffect(() => {
		if (defaultCode) {
			setCode(defaultCode);
		}
	}, [defaultCode]);

	const handleEmailSubmitInternal = () => {
		handleEmailSubmit(email);
	};

	const handleCodeSubmitInternal = () => {
		handleCodeSubmit(email, code);
	};

	const handleReset = () => {
		setEmail("");
		setCode("");
		setIsEmailSent(false);
	};

	return (
		<Box
			sx={{
				maxWidth: 400,
				margin: "auto",
				padding: 2,
				textAlign: "center",
			}}
		>
			<Typography
				variant="h5"
				gutterBottom
				className="text-center py-0 text-3xl text-cyan-800 font-light font-sans"
			>
				Email Verification
			</Typography>
			{!isEmailSent ? (
				<>
					<Typography variant="body1" gutterBottom>
						Please enter an email address below to verify the
						customer's email address.
					</Typography>
					<br />
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<TextField
							fullWidth
							variant="outlined"
							label="Please enter email address"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							margin="none"
							sx={{
								flexGrow: 1,
								"& fieldset": {
									borderTopRightRadius: 0,
									borderBottomRightRadius: 0,
								},
							}}
							disabled={disabled}
						/>
						<Button
							variant="contained"
							color="primary"
							onClick={handleEmailSubmitInternal}
							disableElevation
							sx={{
								height: "56px",
								marginLeft: "-1px",
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
							}}
							disabled={disabled}
						>
							SUBMIT
						</Button>
					</Box>
				</>
			) : (
				<>
					<Typography variant="body1" gutterBottom>
						An email has been sent to the customer at: {email}
					</Typography>
					<br />
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<TextField
							fullWidth
							variant="outlined"
							label="Please enter code"
							value={code}
							onChange={(e) => setCode(e.target.value)}
							margin="none"
							sx={{
								flexGrow: 1,
								"& fieldset": {
									borderTopRightRadius: 0,
									borderBottomRightRadius: 0,
								},
							}}
							disabled={disabled}
						/>
						<Button
							variant="contained"
							color="primary"
							onClick={handleCodeSubmitInternal}
							disableElevation
							sx={{
								height: "56px",
								marginLeft: "-1px",
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
							}}
							disabled={disabled}
						>
							VERIFY
						</Button>
					</Box>
					{!disabled && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: 2,
							}}
						>
							<Button color="primary" onClick={handleReset}>
								<Typography sx={{ fontSize: "0.75rem" }}>
									Reset & Start Over
								</Typography>
							</Button>
							<Button color="primary">
								<Typography sx={{ fontSize: "0.75rem" }}>
									Resend Email
								</Typography>
							</Button>
						</Box>
					)}
				</>
			)}
		</Box>
	);
}

export default RtoEmailVerificationField;
